import { Image } from "@mantine/core";
import React from "react";

export const homeBannersColumns: TTableColumns<THomePageBanners>[] = [
  {
    label: "Banner",
    key: "banner",
    renderCell: (value) => <Image width={100} src={value.banner} />,
    minWidth: 200,
  },
  {
    label: "Mobile Banner",
    key: "mobileBanner",
    renderCell: (value) => <Image width={100} src={value.mobileBanner} />,
    minWidth: 200,
  },
  {
    label: "Title",
    key: "title",
    renderCell: (value) => (
      <div dangerouslySetInnerHTML={{ __html: value.title }} />
    ),
  },
  {
    label: "Color Text",
    key: "color",
    renderCell: (value) => (
      <div dangerouslySetInnerHTML={{ __html: value.colorText }} />
    ),
  },
];

import React, { useEffect } from "react";
import ContentBlock from "../../../components/ContentBlock/ContentBlock";
import {
  ActionIcon,
  Button,
  Flex,
  Grid,
  Group,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { forms } from "../../../forms";
import { showNotification } from "@mantine/notifications";
import { useSettingsQuery } from "../../../hooks/settings/query/useSettings.query";
import { useUpdateSettingsMutation } from "../../../hooks/settings/mutation/useUpdateSettings.mutation";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const Addresses = () => {
  const { isLoading, data } = useSettingsQuery();
  const { isPending, mutateAsync } = useUpdateSettingsMutation();
  const {
    getInputProps,
    onSubmit,
    setValues,
    values,
    removeListItem,
    insertListItem,
  } = useForm({
    initialValues: forms.settings.values,
    validateInputOnBlur: true,
    validateInputOnChange: true,
    validate: yupResolver(forms.settings.validation),
  });

  useEffect(() => {
    if (!isLoading && data) {
      setValues({
        ...data.data,
        addresses:
          data.data.addresses.length > 0
            ? data.data.addresses
            : forms.settings.values.addresses,
      });
    } else {
      setValues(forms.settings.values);
    }
  }, [isLoading, data]);

  const handleSubmit = async (values: typeof forms.settings.values) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      showNotification({ message: res.message, color: "green" });
    } else {
      showNotification({ message: res.data.message, color: "red" });
    }
  };

  const fields = values.addresses.map((item, index) => (
    <Grid gutter={10} key={`${index}`}>
      <Grid.Col lg={3.3}>
        <TextInput
          key={index}
          label="Title"
          {...getInputProps(`addresses.${index}.title`)}
        />
      </Grid.Col>
      <Grid.Col lg={3.3}>
        <TextInput
          key={index}
          label="Address"
          {...getInputProps(`addresses.${index}.address`)}
        />
      </Grid.Col>
      <Grid.Col lg={3.3}>
        <TextInput
          key={index}
          label="MAP Iframe URL"
          {...getInputProps(`addresses.${index}.mapIframe`)}
        />
      </Grid.Col>
      <Grid.Col lg={2}>
        {values.addresses.length > 1 && (
          <ActionIcon
            mt={27}
            color="red"
            onClick={() => removeListItem("addresses", index)}
          >
            <IconTrash />
          </ActionIcon>
        )}
      </Grid.Col>
    </Grid>
  ));

  return (
    <ContentBlock title="Addresses" loading={isLoading}>
      <form onSubmit={onSubmit(handleSubmit)}>
        {fields}
        <Button
          onClick={() => {
            if (values.addresses.length >= 5) {
              showNotification({
                color: "red",
                message: "You can add up to 5 addresses",
              });
            } else {
              insertListItem("addresses", { title: "", address: "" });
            }
          }}
          my={20}
          rightIcon={<IconPlus size={14} color="#fff" />}
          size="xs"
        >
          Add Address
        </Button>
        <Flex>
          <Button
            loading={isPending}
            disabled={isPending}
            type="submit"
            mt={20}
          >
            Update Address
          </Button>
        </Flex>
      </form>
    </ContentBlock>
  );
};

export default Addresses;
